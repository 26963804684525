<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formUser" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="firstname">Nombre completo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="firstname"
              v-model="user.name"
              outlined
              dense
              placeholder="First Name"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="email">Correo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="email"
              v-model="user.email"
              outlined
              dense
              placeholder="Correo"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12" md="3">
                        <label for="mobile">Mobile</label>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                        id="mobile"
                        v-model="mobile"
                        type="number"
                        outlined
                        dense
                        placeholder="Number"
                        hide-details
                        ></v-text-field>
                    </v-col> -->

          <v-col cols="12" md="3">
            <label for="password">Contraseña</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="password"
              v-model="user.password"
              type="password"
              outlined
              dense
              placeholder="Contraseña"
              autocomplete="off"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-checkbox
              v-model="user.status"
              :label="`${user.status ? 'Usuario habilitado' : 'Usuario deshabilitado'}`"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    userEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      user: this.userEdited
        ? { ...this.userEdited }
        : {
            name: '',
            email: '',
            password: '',
            status: true,
          },
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formUser.validate()
    },
    reset() {
      this.user.name = ''
      this.user.email = ''
      this.user.password = ''
      this.$refs.formUser.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const DTO = { ...this.user }
      if (this.userEdited) {
        // // console.log("editando");
        this.$emit('beforeUpdate', DTO)
      } else {
        // // console.log("creando");
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
