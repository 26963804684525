<template>
  <v-card elevation="0">
    <UserHeader @openToAdd="openDialog" @search="changeSearch"></UserHeader>
    <DatatableUsers :users="users" :loading="loading" :search="search" @edit="openEdit" @remove="openRemove"></DatatableUsers>
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormUser
          v-if="dialog"
          :loading="loading"
          :userEdited="userEdited"
          @beforeUpdate="updateUser"
          @beforeCreate="createUser"
        ></FormUser>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableUsers from "./DatatableUsers.vue";
import FormUser from "@/components/forms/FormUser.vue";
import UserHeader from "./UserHeader.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useUsers from "@/composables/useUsers";

export default {
  components: {
    DatatableUsers,
    FormUser,
    UserHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      userEdited,
      users,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createUser,
      updateUser,
    } = useUsers();

    return {
      dialog,
      users,
      search,
      loading,
      userEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createUser,
      updateUser,
    };
  },
};
</script>

<style lang="scss" scoped></style>
