var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded datatable-height",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.users,"loading":_vm.loading,"item-key":"name","fixed-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email_verified_at != null ? "Verificado" : "Sin verificar")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatISODate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatISODate(item.updated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('CrudButtons',{on:{"edit":function($event){return _vm.$emit('edit', item)},"remove":function($event){return _vm.$emit('remove', item)}}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }