const OPT_FORMAT = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
const FORMATTER = new Intl.DateTimeFormat("es-PE", OPT_FORMAT);
const SIMPLE_FORMAT = new Intl.DateTimeFormat("es-PE");

export const formatISODate = (datetime) => {
    try {
        if (!datetime) return "";
        if (!Date.parse(datetime)) return "";
        const timestamp = Date.parse(datetime);
        const DATE = new Date(timestamp)
        return SIMPLE_FORMAT.format(DATE);
    } catch (error) {
        return ""
    }
}