<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="users"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >
      <!-- name -->
      <template #[`item.name`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
            item.name
          }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>
      <template #[`item.email_verified_at`]="{ item }">
        {{ item.email_verified_at != null ? "Verificado" : "Sin verificar" }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="statusColor[status[item.status]]"
          class="font-weight-medium"
        >
          {{ status[item.status] }}
        </v-chip>
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ formatISODate(item.created_at) }}
      </template>
      <template #[`item.updated_at`]="{ item }">
        {{ formatISODate(item.updated_at) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons
            @edit="$emit('edit', item)"
            @remove="$emit('remove', item)"
          ></CrudButtons>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CrudButtons from "@/components/buttons/CrudButtons.vue";
import { formatISODate } from "@/utils/date";
export default {
  components: {
    CrudButtons,
  },
  props: {
    users: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    const statusColor = {
      Activo: "primary",
      Inactivo: "error",
    };

    return {
      headers: [
        { text: "NOMBRES", value: "name" },
        { text: "CORREO", value: "email" },
        { text: "FECHA REGISTRO", value: "created_at" },
        { text: "ULTIMA ACTUALIZACION", value: "updated_at" },
        //{ text: "STATUS", value: "status" },
        { text: "VERIF. EMAIL", value: "email_verified_at" },
        { text: "", value: "actions", align: "right" },
      ],
      status: {
        1: "Activo",
        2: "Inactivo",
        //3: 'Rechazado',
      },
      statusColor,
      formatISODate,
    };
  },
};
</script>
