import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useUsers = () => {

    // base data
    const dialog = ref(false);
    const userEdited = ref(null);
    const users = ref([]);
    const search = ref("");
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        userEdited.value ? "Editar usuario" : "Agregar usuario"
    );
    const subtitleDialog = computed(() =>
        userEdited.value ?
            "Rellene los campos correctamente para modificar los datos del usuario" :
            "Rellene los campos correctamente para validar los datos del nuevo usuario"
    );

    const closeDialog = () => {
        dialog.value = false;
        userEdited.value = null;
    }

    // methods
    const getUsersList = async () => {
        const { data, status } = await store.dispatch("fetchUsers")
        if (status != 200) return;
        // console.log(data)
        users.value = data;
    }

    const createUser = async (user) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createUser", user)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getUsersList();
        closeDialog();
    }

    const updateUser = async (user) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateUser", user)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getUsersList();
        closeDialog();
    }

    const removeUser = async (user) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeUser", user)
        if (status != 200 && status != 201 && status != 204) return;
        await getUsersList();
    }

    getUsersList();

    return {
        dialog,
        userEdited,
        users,
        search,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        changeSearch(ev) {
            search.value = ev
        },
        openDialog() {
            dialog.value = true;
        },
        openEdit(user) {
            dialog.value = true;
            userEdited.value = { ...user };
            // console.log(user)
        },
        openRemove: removeUser,
        closeDialog,
        createUser,
        updateUser
    };
}

export default useUsers;

